import React from 'react';
import "./MyanmarZodiacSigns.css"
import { Col, Container, Row } from 'react-bootstrap';
import BurmeseZodiacSigns from "./../../Images/Burmese-Zodiac-Signs.png"
import AnimalZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Tiger.jpg"
import AnimalLionZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Lion.jpg"
import AnimalEleZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Elephant.jpg"
import AnimalEleSecZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Tuskless-Elephant.jpg"
import AnimalRatZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Rat.jpg"
import AnimalPigZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Guinea-Pig.jpg"
import AnimalDragZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Dragon.jpg"
import AnimalEagZodiacSigns from "./../../Images/Myanmar-Animal-Zodiac-Sign-Dragon.jpg"

const MyanmarZodiacSigns = () => {
  return (
    <Container fluid>
      <Row>
        <Col className="p-0">
          <div className='zodicsignback'>
            <div className='container'>
              <h3 class="text-center signtitle">Myanmar Zodiac Signs (Astrology System)</h3>
              <div className='my-4'>
                <h3 className='zodicsignscolor'>History of Myanmar Zodiac Signs</h3>
                <div className='firstsecul'>Burmese zodiac animal signs have their origin from ancient monks of Myanmar. This astrological system is based on the understanding of the cosmic world, which is incorporated with the observations of skies and the wildlife This astrology branch is called Mahabote, translated to be “little vessel” or “little key” as well. The reason is that it is just a minor branch-off derived from the broader Hindu astrological system. Big things compose of small packages, however, Mahabote is truly a key to extremely powerful insight together with the esoteric realm.</div>
                <div className='text-center pt-3'>
                  <img src={BurmeseZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Burmese Zodiac Signs
                  </div>
                </div>
                <div className='zodictitledetails'>Burmese astrology absolutely relies on the number eight. Not only it balances the cosmos, but it also reflects the harmony of power and promotes a pleasant atmosphere. Here are the vital elements of Eight in Myanmar astrology:</div>
                <ul><li className='firstsecul'>Eight Planetary Energies</li><li className='firstsecul'>Eight Days of the Week</li><li className='firstsecul'>Eight Cardinal Directions</li><li className='firstsecul'>Eight Burmese Zodiac Animal Signs</li></ul>
                <div className='zodictitledetails'>– The ancient Burmese astrological system is based on seven planets, plus Rahu. Rahu is unique for it is a conceptual celestial planet. Theoretically defined, it is the intersection point among the earth, sun and moon at the time of the eclipse. Rahu also serves as an invisible planet of the Burmese zodiac system for astrological purposes. Therefore making the eight planetary entities in the Burmese astrological system.</div>
                <div className='zodictitledetails'>– Mahabote is also based on the eight days of the week. For the extra day, Wednesday is split in half in order to make two days: Wednesday morning (12:01 am to 12:00 p.m.) and Wednesday afternoon (12:01 p.m. to 12:00 a.m.).</div>
                <div className='zodictitledetails'>– Another aspect of this astrological form includes the cardinal directions. As there are eight cardinal directions, each one will move slightly in its distinct symbolic energy. Your direction, which is determined by your day of birth is able to prove beneficial for you. Unlike sun signs, this form of astrology has animal zodiac signs, which are identified by the weekday you were born.</div>
                <div className='zodictitledetails'>Differing from Vietnam zodiac signs which follow the month animals, Burmese zodiac signs follow the weekdays to caculate which animal you are.</div>
                <h3 className='zodicsignscolor mt-2'>Burmese Zodiac Animal Signs</h3>
                <h4 className='pt-2'><span> Zodiac Animal Sign: Lion</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong> Monday</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Moon</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> East</div>
                <div className='firstsecul'>Personality/Attributes of the Tiger:</div>
                <ul><li className='firstsecul'>Intelligent, intuitive, high alert.</li><li className='firstsecul'>An eye for detail</li><li className='firstsecul'>Strong and patient, but only to a point</li><li className='firstsecul'>Dislike being utilized, being wasted time</li><li className='firstsecul'>&nbsp;Responsible, goal-oriented and ambitious</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Tiger (Monday)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Sign: Lion</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong> Tuesday</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Mars</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> Southeast</div>
                <div className='firstsecul'>Personality/Attributes of the Lion:</div>
                <ul><li className='firstsecul'>A natural leader, noble person</li><li className='firstsecul'>Self-respect, strong-willed, opinionated</li><li className='firstsecul'>Decisive, challenge willing taker</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalLionZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Lion (Tuesday)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Sign: Elephant (with tusks)</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong> Wednesday Morning</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Mercury</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> South</div>
                <div className='firstsecul'>Personality/Attributes of the Tusked Elephant:</div>
                <ul><li className='firstsecul'>Unpredictable, enthusiastic</li><li className='firstsecul'>Risk-taker</li><li className='firstsecul'>Spontaneous, passionate</li><li className='firstsecul'>Independent, like to be in control of all situations</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalEleZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Elephant (Wednesday Morning)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Sign: Elephant (no tusks)</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong>Wednesday Afternoon</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Rahu</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> Northwest</div>
                <div className='firstsecul'>Personality/Attributes of the Elephant (no tusks):</div>
                <ul><li className='firstsecul'>Contradictory nature, hard to figure out</li><li className='firstsecul'>Private, detest people meddling in your business</li><li className='firstsecul'>Good at motivating yourself</li><li className='firstsecul'>Certain action taker, successful, achievements accomplished</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalEleSecZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Tusk-less Elephant (Wednesday Afternoon)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Sign: Rat</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong>Thursday</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Jupiter</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> West</div>
                <div className='firstsecul'>Personality/Attributes of the Rat:</div>
                <ul><li className='firstsecul'>Clever, witty, intelligent</li><li className='firstsecul'>Able to tap into resource and find opportunities</li><li className='firstsecul'>Maybe introverted</li><li className='firstsecul'>Unstoppable</li><li className='firstsecul'>Focused and have a knack for getting ahead</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalRatZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Rat (Thursday)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Signs: Guinea Pig</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong>Friday</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Venus</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> North</div>
                <div className='firstsecul'>Personality/Attributes of the Guinea Pig:</div>
                <ul><li className='firstsecul'>Naturally artistic, creative</li><li className='firstsecul'>Have tons of fabulous ideas, but hard to complete</li><li className='firstsecul'>Go in various directions at once</li><li className='firstsecul'>Easily get bored and tired</li><li className='firstsecul'>Loving, kind and sympathetic, sensitive</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalPigZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Guinea Pig (Friday)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Sign: Dragon</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong>Saturday</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Saturn</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> Southwest</div>
                <div className='firstsecul'>Personality/Attributes of the Dragon:</div>
                <ul><li className='firstsecul'>Philosophical, understanding</li><li className='firstsecul'>Naturally attracted for an easy confidence</li><li className='firstsecul'>Not like depending on others</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalEagZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Dragon (Saturday)
                  </div>
                </div>
                <h4 className='pt-2'><span>Zodiac Animal Sign: Garuda (mythical bird, Hindu/Buddhist bird deity)</span></h4>
                <div className='firstsecul'><strong>Day of Week Born:</strong>Sunday</div>
                <div className='firstsecul'><strong>Ruling Planet:</strong> Sun</div>
                <div className='firstsecul'><strong>Ruling Direction:</strong> Northeast</div>
                <div className='firstsecul'>Personality/Attributes of the Garuda:</div>
                <ul><li className='firstsecul'>Kind, generous</li><li className='firstsecul'>Overly gracious</li><li className='firstsecul'>Challenge willing taker</li><li className='firstsecul'>The tougher the obstacle the more motivated you are</li><li className='firstsecul'>Energetic, optimistic, motivative to others</li></ul>
                <div className='text-center pt-3'>
                  <img src={AnimalDragZodiacSigns} alt='img' className='zodiacimgwidth'/>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='imgtitlefirst mb-3'>
                    Myanmar Animal Zodiac Sign – Garuda Eagle (Sunday)
                  </div>
                </div>
                <h3 className='zodicsignscolor'>What are Myanmar Zodiac Signs?</h3>
                <div className='zodictitledetails'>Myanmar Zodiac signs are the traditional astrological system. It was invented by the ancient monks. They created this system by combining their knowledge of the cosmic world and their observations of the skies and the animal kingdom.</div>
                <div className='zodictitledetails'>This ancient branch of astrology is called Mahabote. It is translated to mean “little vessel” or “little key”. The meaning is probably because Mahabote is a smaller branch-off from the larger Hindu astrological system.</div>
                <div className='zodictitledetails'>Unlike Western astrology, the Myanmar astrology system has only eight zodiac signs. Eight is considered as scared number. It is said the number eight reflects harmony in energy: deflecting imbalance and ever perpetuating congruence.</div>
                <div className='zodictitledetails'>There are four essential elements in Myanmar astrology and all of them relate to number eight:</div>
                <div className='zodictitledetails'><u>♦ Eight Planetary Energies:</u> the Sun, the Moon, Mercury, Venus, Mars, Jupiter, Saturn and a secret Burmese planet named Rahu.</div>
                <div className='zodictitledetails p-0'>Rahu is unique that it is not a planet, but a conceptual celestial presence. Literally defined, Rahu is the point of intersection between the earth, sun and moon at the time of eclipse.</div>
                <div className='zodictitledetails'><u>♦ Eight Days of the Week</u></div>
                <div className='zodictitledetails p-0'>In order to have one extra day, the ancient Myanmar monks split Wednesday in half making two days: Wednesday morning (12:01 a.m to 12:00 p.m.) and Wednesday afternoon (12:01 p.m. to 12:00 a.m.).</div>
                <div className='zodictitledetails'><u>♦ Eight Cardinal Directions:</u> North, South, East, West, Northeast, Northwest, Southeast, and Southwest.</div>
                <div className='zodictitledetails p-0'>Each direction vibrates in its own distinct symbolic energy. According to your day of birth, your direction should prove beneficial for you.</div>
                <div className='zodictitledetails'><u>♦ Eight Burmese Zodiac Animal Signs:</u> Elephant with and without tusk, Rat, Garuda, Lion, Tiger, Dragon and Guinea pig.</div>
                <div className='zodictitledetails p-0'>Despite the animals of the zodiac have been existed for thousands of years, people are not clear why these animals were selected. But it is true that the animals are reputed to demonstrate parts of the universe. The animal signs do give a rough idea of the typical traits seen in individuals born under the zodiac sign.</div>
                <h3 className='zodicsignscolor mt-4'>How is it important in Burmese life?</h3>
                <div className='zodictitledetails'>The purpose of the Burmese Zodiac Signs Reading is to provide information on the nature and disposition of an individual. This reading is extremely detailed in the information that you are provided. When knowing about the sign, people will be appropriated direction in the life. This helps you to make yourself into a better human being and to know your way of working.</div>
                <div className='zodictitledetails'>The Myanmar zodiac signs have very important role in life of Burmese people. Astrology is big business and astrologers are everywhere. Any Burmese people know their sign and use it to get advice from astrologers for every events in life. Astrologers will base on each people’s sign and advise them in naming for their children, deciding an auspicious date for a wedding, starting a business or going to a new job….</div>
                <div className='zodictitledetails'>In Burmese temples, there are shrines with the animals symbolizing each day of the week around the base. The people will find the shrines according to the day of birth, donate the flowers and pour water over the Buddha in the shrine. It is believed to provide good luck in the future. In addition, they can go to pray at the direction corresponding to their sign and at other directions depending on the advice of an astrologer.</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MyanmarZodiacSigns;
