const Holidaydata = () => {
    return {
        "version": 3,
        "title": "Holidays and Observances in Myanmar in 2024",
        "holidays": [
            {
                "name": "New Year's Day",
                "types": "National Holiday",
                "day": "Monday",
                "date": "1 Jan 2024",
                "comments": "--"
            }, {
                "name": "Independence Day",
                "types": "National Holiday",
                "day": "Thursday",
                "date": "4 Jan 2024",
                "comments": "--"
            }, {
                "name": "Kayin New Year Day",
                "types": "National Holiday",
                "day": "Thursday",
                "date": "11 Jan 2024",
                "comments": "First day of Pyathoe"
            }, {
                "name": "Union Day",
                "types": "National Holiday",
                "day": "Monday",
                "date": "Feb 12 2024",
                "comments": "--"
            }, {
                "name": "Peasants' Day",
                "types": "National Holiday",
                "day": "Saturday",
                "date": "Mar 02 2024",
                "comments": "--"
            }, {
                "name": "Full Moon Day of Tabaung",
                "types": "National Holiday",
                "day": "Sunday",
                "date": "Mar 24 2024",
                "comments": "Date varies depending on the lunar cycle"
            }, {
                "name": "Armed Forces Day",
                "types": "National Holiday",
                "day": "Wednesday",
                "date": "Mar 27 2024",
                "comments": "Tatmadaw Day"
            }, {
                "name": "Thingyan",
                "types": "National Holiday",
                "day": "Saturday",
                "date": "Apr 13 2024",
                "comments": "Water festival"
            }, {
                "name": "Thingyan Holiday",
                "types": "National Holiday",
                "day": "Sunday",
                "date": "Apr 14 2024",
                "comments": "--"
            }, {
                "name": "Thingyan Holiday",
                "types": "National Holiday",
                "day": "Monday",
                "date": "Apr 15 2024",
                "comments": "--"
            }, {
                "name": "Thingyan Holiday",
                "types": "National Holiday",
                "day": "Tuesday",
                "date": "Apr 16 2024",
                "comments": "--"
            }, {
                "name": "Burmese New Year",
                "types": "National Holiday",
                "day": "Wednesday",
                "date": "Apr 17 2024",
                "comments": "--"
            }, {
                "name": "Burmese New Year Holiday",
                "types": "National Holiday",
                "day": "Thursday",
                "date": "Apr 18 2024",
                "comments": "--"
            }, {
                "name": "Burmese New Year Holiday",
                "types": "National Holiday",
                "day": "Friday",
                "date": "Apr 19 2024",
                "comments": "--"
            }, {
                "name": "Burmese New Year Holiday",
                "types": "National Holiday",
                "day": "Saturday",
                "date": "Apr 20 2024",
                "comments": "--"
            }, {
                "name": "Burmese New Year Holiday",
                "types": "National Holiday",
                "day": "Sunday",
                "date": "Apr 21 2024",
                "comments": "--"
            }, {
                "name": "Labour Day",
                "types": "National Holiday",
                "day": "Wednesday",
                "date": "May 01 2024",
                "comments": "May Day"
            }, {
                "name": "Full Moon Day of Kasong",
                "types": "National Holiday",
                "day": "Wednesday",
                "date": "May 22 2024",
                "comments": "Birth of Buddha"
            }, {
                "name": "Eid ul-Adha Day",
                "types": "National Holiday",
                "day": "Monday",
                "date": "Jun 17 2024",
                "comments": "Date subject to change"
            }, {
                "name": "Martyr's Day",
                "types": "National Holiday",
                "day": "Friday",
                "date": "Jul 19 2024",
                "comments": "--"
            }, {
                "name": "Full Moon Day of Waso",
                "types": "National Holiday",
                "day": "Saturday",
                "date": "Jul 20 2024",
                "comments": "Dhammasetkya Day. Beginning of Buddhist Lent"
            }, {
                "name": "Full Moon Day of Thadingyut",
                "types": "National Holiday",
                "day": "Wednesday",
                "date": "Oct 16 2024",
                "comments": "End of Buddhist Lent"
            }, {
                "name": "Full Moon of Thadingyut Holiday Holiday",
                "types": "National Holiday",
                "day": "Thursday",
                "date": "Oct 17 2024",
                "comments": "End of Buddhist Lent"
            }, {
                "name": "Full Moon of Thadingyut Holiday Holiday",
                "types": "National Holiday",
                "day": "Friday",
                "date": "Oct 18 2024",
                "comments": "End of Buddhist Lent"
            }, {
                "name": "Deepavali",
                "types": "National Holiday",
                "day": "Friday",
                "date": "Nov 01 2024",
                "comments": "Date subject to change"
            }, {
                "name": "Full Moon of Tazaungmone",
                "types": "National Holiday",
                "day": "Friday",
                "date": "Nov 15 2024",
                "comments": "--"
            }, {
                "name": "National Day",
                "types": "National Holiday",
                "day": "Monday",
                "date": "Nov 25 2024",
                "comments": "--"
            }, {
                "name": "Christmas Day",
                "types": "National Holiday",
                "day": "Wednesday",
                "date": "Dec 25 2024",
                "comments": "--"
            }, {
                "name": "Kayin New Year Day",
                "types": "National Holiday",
                "day": "Monday",
                "date": "Dec 30 2024",
                "comments": "First day of Pyathoe"
            }, {
                "name": "New Year",
                "types": "National Holiday",
                "day": "Tuesday",
                "date": "Dec 31 2024",
                "comments": "--"
            }
        ]
    }
}

export default Holidaydata;