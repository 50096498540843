import React, { useEffect, useState, useRef } from 'react';
import "./Calender.css";
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { getMCalenderData, getMCalenderDaysData, getMCalenderStaticData } from '../../CalenderFunction/calenderData';
import { Link, useNavigate } from 'react-router-dom';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { PiGreaterThanBold } from 'react-icons/pi';
import { ceMmDateTime } from '../../CalenderFunction/calender';
import Holidaydata from '../../CalenderFunction/holiday';

const MyanmarCalendar = () => {
   // State declarations
   const [year, setYear] = useState("");
   const [newAAYear, setNewYear] = useState(null);
   const [month, setMonth] = useState(null);
   const [dates, setDates] = useState([]);
   const [currentDate, setCurrentDate] = useState({});
   const [selectDate, setSelectDate] = useState({});
   const [monthList, setMonthList] = useState([]);
   const [calenderType, setCalenderType] = useState([]);
   const [calenderLanguage, setCalenderLanguage] = useState([]);
   const [calenderTypeId, setCalenderTypeId] = useState(0);
   const [calenderLanguageId, setCalenderLanguageId] = useState(0);
   const [weekdayHeader, setweekdayHeader] = useState([]);
   const [calenderData, setCalenderData] = useState([]);
   const [jsId, setJsId] = useState("");
   const [headerLine, setHeaderLine] = useState("");
   const [data, setData] = useState({});
   const [daFootUl, setDaFootUl] = useState([]);
   const [holidays, setHolidays] = useState([]);
   const monthRefs = useRef([]);
   const [fullMoon, setFullMoon] = useState([])
   const [newMoon, setNewMoon] = useState([])
   const [waxingMoon, setWaxingMoon] = useState([])
   const [WaningMoon, setWaningMoon] = useState([])
   const [englishMonth, setEnglishMonth] = useState("")
   const [englishYears, setEnglishYears] = useState("")
   const [monthName, setMonthName] = useState("")

   // Fetch static data on initial render
   useEffect(() => {
      const fetchData = async () => {
         const data = await getMCalenderStaticData(year);
         setMonthList(data.month);
         setCalenderType(data.type);
         setCalenderLanguage(data.language);
      };
      fetchData();
   }, [year]);

   useEffect(() => {
      const today = new Date();
      setCurrentDate({
         date: today.getDate(),
         month: month,
         year: newAAYear,
      });
   }, [newAAYear]);


   // Fetch calendar data when monthList, calenderTypeId, or calenderLanguageId changes
   useEffect(() => {
      const fetchYearDate = async () => {
         const data = await getMCalenderData(month, year, calenderTypeId, calenderLanguageId);
         setweekdayHeader(data.WeekdayHeader);
         setHeaderLine(data.headerLine);
         setEnglishYears(data.EnglishYear)
         setEnglishMonth(data.englishMonth)
         const chunks = chunkArray(data.calenderArr, 7);
         const englishDays = extractEnglishDays(chunks);
         setCalenderData(englishDays);
         const staticData = await getMCalenderStaticData(data?.year);
         const currentMonth = staticData?.month?.find(m => m.name === data.month);
         if (month === null) {
            setMonth(currentMonth.id)
            setMonthName(currentMonth.name)
         }
         if (year === "") {
            setYear(data.year);
            setNewYear(data.year)
         }

         const fullMoonDays = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "Full Moon")
            .map((ele) => ele.EnglishDay);
         setFullMoon(fullMoonDays);


         const newMoonDays = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "New Moon")
            .map((ele) => ele.EnglishDay);
         setNewMoon(newMoonDays);

         const WaxingDay = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "Waxing 8")
            .map((ele) => ele.EnglishDay);
         setWaxingMoon(WaxingDay);

         const WaningDay = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "Waning 8")
            .map((ele) => ele.EnglishDay);
         setWaningMoon(WaningDay);

      };
      fetchYearDate();
   }, [month, year, monthList, calenderTypeId, calenderLanguageId]);

   // Scroll to the current month when the month changes
   useEffect(() => {
      if (monthRefs.current[month]) {
         monthRefs.current[month].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
   }, [month]);

   // Handle month change (up or down)
   const handleMonthChange = (value) => {
      var v = value;
      var SY = 1577917828 / 4320000; // solar year (365.2587565)
      var MO = 1954168.050623; // beginning of 0 ME
      var me = month;
      var mn = Number(me);
      var ye = year;
      var yn = Number(ye);
      var j1 = Math.round(SY * yn + MO) + 1;
      var j2 = Math.round(SY * (yn + 1) + MO);
      var M1 = ceMmDateTime.j2m(j1);
      var M2 = ceMmDateTime.j2m(j2);
      var si = M1.mm;
      var ei = M2.mm;

      if (mn === 0) mn = (v === 1) ? 4 : 3;
      else if (mn === 4 && M1.myt !== 0 && v !== 1) mn = 0;
      else if (mn === 3 && M1.myt !== 0 && v === 1) mn = 0;
      else {
         mn += Number(v);
         if (mn < si) {
            mn += 12;
            yn--;
         } else if (mn > ei) {
            mn = mn % 12;
            yn++;
         }
      }

      setMonth(mn);
      setYear(yn);
      if (mn == currentDate.month && yn == currentDate.year) {
         fetchCurrentDateData();
         setSelectDate({})
       } else {
         fetchSelectDateData();
       }
   };

   useEffect(() => {
      if (monthRefs.current[month]) {
         monthRefs.current[month].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
         // Highlight the month
         monthRefs.current[month].classList.add('active-month');
      }

      // Remove highlight from previously active-month month
      return () => {
         if (monthRefs.current[month]) {
            monthRefs.current[month].classList.remove('active-month');
         }
      };
   }, [month]);


   // Handle year input change
   const handleYearChange = (e) => {
      const value = e.target.value;
      if (value === '' || /^[0-9]+$/.test(value)) {
         setYear(value);
      }
   };


   // Increase year by 1
   const increaseYear = () => {
      setYear(prevYear => {
        const newYear = parseInt(prevYear, 10) + 1;
        const finalYear = newYear > 1500 ? 1500 : newYear;
        // Check if the new year and current month match the current date's year and month
        if (finalYear == currentDate.year && month == currentDate.month) {
          fetchCurrentDateData();
          setSelectDate({})
        } else {
          fetchSelectDateData();
        }
    
        return finalYear;
      });
    };
    

   // Decrease year by 1
   const decreaseYear = () => {
      setYear(prevYear => {
        const newYear = parseInt(prevYear, 10) - 1;
        const finalYear = newYear < 1 ? 1 : newYear;
    
        // Check if the new year and current month match the current date's year and month
        if (finalYear == currentDate.year && month == currentDate.month) {
          fetchCurrentDateData();
          setSelectDate({})
        } else {
          fetchSelectDateData();
        }
    
        return finalYear;
      });
    };
    

   // Handle year input blur (validate and set year)
   const handleYearBlur = () => {
      const newYear = parseInt(year, 10);
      if (!isNaN(newYear)) {
         if (newYear < 1) {
            setYear(1);
         } else if (newYear > 1500) {
            setYear(1500);
         } else {
            setYear(newYear);
         }
      }
   };

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleYearBlur();
      }
   };


   // Fetch data for the selected date
   useEffect(() => {
      const fetchSelectDate = async () => {
         const data = await getMCalenderDaysData(jsId, calenderTypeId, calenderLanguageId);
         setData(data);
      }
      fetchSelectDate();
   }, [jsId, calenderTypeId, calenderLanguageId]);

   // Fetch data for the current date on initial render
   useEffect(() => {
      fetchCurrentDateData();
   }, [currentDate, calenderTypeId, calenderLanguageId]);

   const fetchCurrentDateData = async () => {
      if (currentDate.date && currentDate.month != null && currentDate.year) {
         const jsId = (await getMCalenderData(currentDate.month, currentDate.year, calenderTypeId, calenderLanguageId)).calenderArr.find(
            day => day.englishDaysClass === "PriDayToday"
         )?.js;

         if (jsId) {
            const data = await getMCalenderDaysData(jsId, calenderTypeId, calenderLanguageId);
            setData(data);
         }
      }
   };

   useEffect(() => {
      fetchSelectDateData();
   }, [selectDate, calenderTypeId, calenderLanguageId]);

   const fetchSelectDateData = async () => {
      if (selectDate.EnglishDay && selectDate.month != null && selectDate.year) {
         const jsId = (await getMCalenderData(selectDate.month, selectDate.year, calenderTypeId, calenderLanguageId)).calenderArr.find(
            day => day.EnglishDay === selectDate.EnglishDay
         )?.js;
         if (jsId) {
            const data = await getMCalenderDaysData(jsId, calenderTypeId, calenderLanguageId);
            setData(data);
         }
      } 
   };

   // Helper function to chunk array into smaller arrays of given size
   function chunkArray(array, chunkSize) {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
         chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
   }

   // Helper function to extract English days from the calendar data
   function extractEnglishDays(chunks) {
      return chunks.map(chunk =>
         chunk.map(item => ({
            EnglishDay: item.EnglishDay || "",
            js: item.js || "",
            englishDaysClass: item.englishDaysClass || "",
         }))
      );
   }

   // Generate calendar dates for the specified year and month
   const generateCalendarDates = (year, month) => {
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const dates = [];
      let week = Array(firstDayOfMonth).fill('');

      for (let day = 1; day <= daysInMonth; day++) {
         if (week.length === 7) {
            dates.push(week);
            week = [];
         }
         week.push(day);
      }

      while (week.length < 7) {
         week.push('');
      }
      dates.push(week);

      return dates;
   };

   // Generate calendar dates whenever year or month changes
   useEffect(() => {
      setDates(generateCalendarDates(year, month));
   }, [year, month]);

   // Fetch holiday data on initial render
   useEffect(() => {
      const fetchHolidayData = async () => {
         const data = await Holidaydata();

         const shortMonth = englishMonth.slice(0, 3);
         const updatedHolidays = data.holidays.filter(holiday => {
            const [monthS, , holidayYear] = holiday.date.split(" ");
            return monthS === shortMonth && holidayYear === englishYears;
         });

         setHolidays(updatedHolidays);
      };

      fetchHolidayData();
   }, [englishMonth, englishYears]);

   // Set jsId when a date is clicked
   const setJsIdfun = (id) => {
      setJsId(id);
   };

   const navigate = useNavigate();

   const moveToCalender = () => {
      navigate("/")
   }

   return (
      <Container fluid>
         <Row className='setscreenheight'>
            <Col xl={3} lg={4} md={3} className='p-0'>
               <div className='calendersecondsec'>
               {window.innerWidth >= 768 &&
                  <div className='calenderword'>MM Calendar</div>}
                  {window.innerWidth >= 768 &&
                     <div className="text-center setyearmonth">
                        <div className='pb-md-3 setyearresponsive'>
                           <div className="d-flex align-items-center justify-content-center manageinputwidth">
                              <FaCaretLeft className='iconcolorsetanother' size={35} onClick={decreaseYear} />
                              <Form.Control
                                 type="text"
                                 value={year || ''}
                                 onChange={handleYearChange}
                                 onBlur={handleYearBlur}
                                 onKeyDown={handleKeyDown}
                              />
                              <FaCaretRight className='iconcolorsetanother' size={35} onClick={increaseYear} />
                           </div>
                        </div>

                        <div className='setarrowres'>
                           {window.innerWidth >= 768 &&
                              <TiArrowSortedUp size={35} className='iconcolorset my-4' onClick={() => handleMonthChange(-1)} />
                           }
                           {window.innerWidth <= 767 &&
                              <FaCaretLeft size={35} className='iconcolorset my-4' onClick={() => handleMonthChange(-1)} />
                           }
                           <div className="calandermonthscroll setmonthresponsive">
                              {monthList.map((monthName, index) => (
                                 <div
                                    key={monthName.id}
                                    ref={(el) => monthRefs.current[monthName.id] = el}
                                    className={`monthcolorset pb-md-4 ${month === monthName.id ? 'active-month' : ''}`}
                                    onClick={() => setMonth(monthName.id)}
                                 >
                                    {monthName.name}
                                 </div>
                              ))}
                           </div>
                           {window.innerWidth >= 768 &&
                              <TiArrowSortedDown size={35} className='iconcolorset mb-4' onClick={() => handleMonthChange(1)} />
                           }
                           {window.innerWidth <= 767 &&
                              <FaCaretRight size={35} className='iconcolorset my-4' onClick={() => handleMonthChange(1)} />
                           }
                        </div>


                     </div>
                  }
                  {window.innerWidth < 768 &&
                     <Row className='m-0 py-4'>
                        <Col lg={6} xs={6}>
                           <div className="d-flex align-items-center justify-content-center manageinputwidth">
                              <FaCaretLeft className='iconcolorsetanother' size={40} onClick={decreaseYear} />
                              <Form.Control
                                 type="text"
                                 value={year || ''}
                                 onChange={handleYearChange}
                                 onBlur={handleYearBlur}
                                 onKeyDown={handleKeyDown}
                              />
                              <FaCaretRight className='iconcolorsetanother' size={40} onClick={increaseYear} />
                           </div>
                        </Col>
                        <Col lg={6} xs={6}>
                           <div className='d-flex align-items-center'>
                              <FaCaretLeft size={40} className='iconcolorsetmobil' onClick={() => handleMonthChange(-1)} />
                              <div className="w-100 conditionalset">
                                 {monthList.map((monthName, index) => (
                                    <div
                                       key={monthName.id}
                                       ref={(el) => monthRefs.current[monthName.id] = el}
                                       className={`monthcolorset pb-md-4 ${month === monthName.id ? 'active-month' : ''}`}
                                       onClick={() => setMonth(monthName.id)}
                                    >
                                       {monthName.name}
                                    </div>
                                 ))}
                              </div>
                              <FaCaretRight size={40} className='iconcolorsetmobil' onClick={() => handleMonthChange(1)} />
                           </div>
                        </Col>
                     </Row>
                  }
                  <div className='wasosetflex'>
                     <div className='setwasocalender'>
                        <div className='wasocalender' onClick={moveToCalender}>
                           English<PiGreaterThanBold size={14} />Myanmar Calendar
                        </div>
                     </div>
                  </div>
               </div>

            </Col>
            <Col xl={9} lg={8} md={9} className='p-0'>
               <div className='calenderthirdsection'>
                  <div className="setmenubar">
                     {window.innerWidth <= 575 && <div className="setselectmenu">
                        <select className="form-select setselectcolor" onChange={(e) => { setCalenderTypeId(e.target.value); }}>
                           {calenderType.map(option => (
                              <option key={option.id} value={option.id}>
                                 {option.name}
                              </option>
                           ))}
                        </select>
                        <select className="form-select setselectcolor" onChange={(e) => { setCalenderLanguageId(e.target.value); }}>
                           {calenderLanguage.map(option => (
                              <option key={option.id} value={option.id}>
                                 {option.name}
                              </option>
                           ))}
                        </select>
                     </div>}
                     <div className='datessetfont'>{monthList.find(m => m.id == month)?.name} {year}</div>
                     {window.innerWidth >= 576 &&

                        <div className="setselectmenu">
                           <select className="form-select setselectcolor" onChange={(e) => { setCalenderTypeId(e.target.value); }}>
                              {calenderType.map(option => (
                                 <option key={option.id} value={option.id}>
                                    {option.name}
                                 </option>
                              ))}
                           </select>
                           <select className="form-select setselectcolor" onChange={(e) => { setCalenderLanguageId(e.target.value); }}>
                              {calenderLanguage.map(option => (
                                 <option key={option.id} value={option.id}>
                                    {option.name}
                                 </option>
                              ))}
                           </select>
                        </div>
                     }
                  </div>
                  <div className='headerline'>{headerLine}</div>
                  <Table className="calendar-table mb-1">
                     <thead>
                        <tr>
                           {weekdayHeader.map((day, index) => (
                              <th key={index} className="calendar-header">{day}</th>
                           ))}
                        </tr>
                     </thead>
                     <tbody>
                        {calenderData.map((week, index) => (
                           <tr key={index}>
                              {week.map((date, idx) => {
                                 var toDayDateClass = date.englishDaysClass;
                                 let currentDatestyle = "d-flex justify-content-center";
                                 let currentDatestylediv = "";
                                 let lightcoloursetselect = "lightcolourset-unselect";
                                 if (
                                    toDayDateClass == "PriDayToday"
                                    // currentDate.date == date.EnglishDay &&
                                    // currentDate.year == year &&
                                    // currentDate.month == month
                                 ) {
                                    currentDatestylediv = "currentDatecoloure";
                                    lightcoloursetselect = "lightcolourset-currentDate";
                                 }
                                 if (
                                    selectDate.EnglishDay == date.EnglishDay &&
                                    selectDate.year == year &&
                                    selectDate.month == month &&
                                    selectDate !== ""
                                 ) {
                                    currentDatestylediv = "selectedDatecoloure";
                                    lightcoloursetselect = "lightcolourset-select";
                                 }
                                 return (
                                    <td
                                       key={idx}
                                    >
                                       <div className={currentDatestyle}>
                                          <div className={lightcoloursetselect} onClick={() => {
                                             if (date.EnglishDay) {
                                                setSelectDate({ EnglishDay: date.EnglishDay, month, year });
                                                setJsIdfun(date.js);
                                             }
                                          }}>
                                             <div className={currentDatestylediv}>{date.EnglishDay}</div>
                                          </div>
                                       </div>
                                    </td>
                                 );
                              })}
                           </tr>
                        ))}
                     </tbody>
                  </Table>

                  <div className='managesdetailscroll'>
                     <div className='managespacedetails'>
                        {
                           data?.MyanmarDate &&
                           <>
                              <div className='sidebardetailsfirstsizeset'>{data.SasanaYear}</div>
                              <div className='sidebardetailsfirstsizeset'>{data.MyanmarYear}</div>
                              <div className={data.moonPhaseClass}>{data.moonPhase}</div>
                              <div className='sidebardetailsfirstsizeset'>{data.weekday}</div>
                              {
                                 data?.sabbathAndMoon && <>
                                    <div className='calenderdatesset d-flex align-items-center'>{data?.sabbathAndMoon}  {data?.canvas && <div className={data?.canvas} />}</div>
                                 </>
                              }
                              {
                                 data?.holidays.length !== 0 && (
                                    <div
                                       className='MCPubHol'
                                       dangerouslySetInnerHTML={{ __html: data.holidays.join('\n').replace(/\n/g, '<br />') }}
                                    />
                                 )
                              }
                              {
                                 data?.holidays2.length !== 0 && (
                                    <div
                                       className='weekdayclass'
                                       dangerouslySetInnerHTML={{ __html: data.holidays2.join('\n').replace(/\n/g, '<br />') }}
                                    />
                                 )
                              }
                              {data.yatyaza && <div className='calenderdatesset'>{data.yatyaza}</div>}
                              {data.pyathada && <div className='calenderdatesset'>{data.pyathada}</div>}
                              {
                                 data?.astro.length !== 0 && (
                                    <div
                                       className='sidebardetails'
                                       dangerouslySetInnerHTML={{ __html: data.astro.join('\n').replace(/\n/g, '<br />') }}
                                    />
                                 )
                              }
                              <div className='sidebardetails'>{data.yearName}</div>
                              <div className='sidebardetails'>{data.mahabote}</div>
                              <div className='sidebardetails'>{data.nakhat}</div>
                              <div className='sidebardetails'>{data.nagaHead}</div>
                              {
                                 data?.ThingyanLink && (
                                    <Link
                                       to={data.ThingyanLink}
                                       className='anchorFootMC'
                                       target="_blank"

                                    >
                                       Thingyan Calculator &gt;&gt;
                                    </Link>
                                 )
                              }
                              <div className='sidebardetailsfontsmall'>{data.DaFoot}</div>
                              {
                                 data?.MCAstro && <div className='sidebardetails'>{data?.MCAstro}</div>
                              }
                              {/* <ul className="">
                              {data.daFootUl.map((item, index) => (
                                 <li key={index} className="">
                                    <div></div>
                                    <Link to={item.r_url} target="_blank" >{item.r_url}</Link>
                                    <div>{item.r_name}</div>
                                    <Link to={item.dyn_url} target="_blank" >{item.dyn_url}</Link>
                                    <div>{item.dyn_Description}</div>
                                 </li>
                              ))}
                           </ul> */}
                              {
                                 data?.ImportantNote && <div className='sidebardetailsfontsmall'>{data?.ImportantNote}</div>
                              }
                           </>
                        }
                     </div>
                     <div className='phaseofmoon py-1 d-flex justify-content-center align-items-center flex-wrap'>
                        <div className='moonsetcenter text-white'>Phases of the Moon : </div>
                        <div className='d-flex align-items-center moongap pl-1'>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {newMoon.join(",")}:</span> <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                              <rect width="13" height="13" fill="black" rx="50" ry="50" />
                           </svg>
                           </div>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {waxingMoon.join(",")}:</span> <svg width="13" height="13" viewBox="0 0 100 100">
                              <path d="M 50 0 A 50 50 0 0 0 50 100 L 50 50 Z" fill="black" />
                              <path d="M 50 0 A 50 50 0 0 1 50 100 L 50 50 Z" fill="white" />
                           </svg>
                           </div>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {fullMoon.join(",")}:</span> <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                              <rect width="13" height="13" fill="white" rx="50" ry="50" />
                           </svg>
                           </div>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {WaningMoon.join(",")}:</span> <svg width="13" height="13" viewBox="0 0 100 100">
                              <path d="M 50 0 A 50 50 0 0 0 50 100 L 50 50 Z" fill="white" />
                              <path d="M 50 0 A 50 50 0 0 1 50 100 L 50 50 Z" fill="black" />
                           </svg>

                           </div>
                        </div>
                     </div>
                     <div className='setholidayscroll'>
                        {holidays.length > 0 && (
                           <>
                              <div className='holidaytitle'>Holiday and Observances</div>
                              {holidays.map((holiday, index) => (
                                 <div key={index} className='holidaydataspace'>
                                    <span className='datecolorset'> {holiday.date.split(" ")[1]}</span> : <span className='holidayname'>{holiday.name}
                                       {holiday.comments && holiday.comments !== '--' ? ` (${holiday.comments})` : ''}</span>
                                 </div>
                              ))}
                           </>
                        )}
                     </div>
                  </div>
               </div>
            </Col>
         </Row>
      </Container>
   );
};

export default MyanmarCalendar;
