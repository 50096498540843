import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Sidebar } from 'react-pro-sidebar';
import { SlCalender } from "react-icons/sl";
import { IoMenuOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import mountaintree from "./Images/20248.png";
import playstore from "./Images/playstore.png";
import applestore from "./Images/applestore.png";
import sunset from "./Images/sunset.png";
import AppRoutes from './AppRoutes';
import "./App.css"
import { Spinner } from 'react-bootstrap';
import { FaCannabis } from 'react-icons/fa';

function App() {
  // State declarations
  const [active, setActive] = useState(localStorage.getItem('active') || 'Calendar');
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(window.matchMedia('(max-width: 800px)').matches);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Update broken state on window resize
  useEffect(() => {
    const handleResize = () => setBroken(window.matchMedia('(max-width: 800px)').matches);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle sidebar item click
  const handleItemClick = (item) => {
    setActive(item);
    setToggled(false);
    navigate(item === 'Calendar' ? '/' : item === 'Holiday' ? '/holiday' : '/myanmar-zodiac-signs');
  };

  useEffect(() => {
    localStorage.setItem('active', active);
    // if (active === 'Calendar') {
    //   navigate('/');
    // } else if (active === 'Holiday') {
    //   navigate('/holiday');
    // }
  }, [active, navigate]);

  // Simulate loading content
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ display: 'flex', height: '100dvh' }}>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" className='spinercolorset' />
        </div>
      ) : (
        <>
          {/* Sidebar */}
          <Sidebar
            toggled={toggled}
            customBreakPoint="800px"
            onBreakPoint={setBroken}
            closeOnClick={true}
            onBackdropClick={() => setToggled(false)}
          >
            <div className="sidebarsetheight">
              <div className='calenderfirstsection position-relative'>
                <div className='settreecenter d-flex align-items-center justify-content-center'>
                  <div>
                    <div className='text-center'>
                      <img src={mountaintree} alt="Mountain" className='mountaintree' />
                    </div>
                    <div className='calendertreehouse'>Myanmar Calendar ~ 1500 Years </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`d-flex align-items-center justify-content-center py-4 cursorpointer ${active === 'Calendar' ? 'active-item' : ''}`}
                    onClick={() => handleItemClick('Calendar')}
                  >
                    <SlCalender size={25} className='calenderwhite' />
                    <div className='calendarword'>Calendar</div>
                  </div>
                  <div
                    className={`d-flex align-items-center justify-content-center py-4 cursorpointer ${active === 'Holiday' ? 'active-item' : ''}`}
                    onClick={() => handleItemClick('Holiday')}
                  >
                    <div className='sunset'></div>
                    {/* <img src={sunset} alt="img" className='sunset' /> */}
                    <div className='todolist text-center'>Holiday</div>
                  </div>
                  <div
                    className={`d-flex align-items-center justify-content-center py-4 cursorpointer ${active === 'Myanmar Zodiac Signs' ? 'active-item' : ''}`}
                    onClick={() => handleItemClick('Myanmar Zodiac Signs')}
                  >
                    <FaCannabis size={25} className='calenderwhite' />
                    {/* <img src={sunset} alt="img" className='sunset' /> */}
                    <div className='todolist text-center'>Myanmar Zodiac Signs</div>
                  </div>
                </div>
                <div className='setversion'>
                  <div className='versioncheck'>
                    <a href="#" target="_blank">
                      <img src={playstore} alt="img" className='playstore' />
                    </a>
                    <a href="#" target="_blank">
                      <img src={applestore} alt="img" className='applestore' />
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </Sidebar>

          {/* Main content */}
          {window.innerWidth >= 768 &&
            <main className='mainwidthset'>
              {broken && (
                <div className='position-absolute menuiconset'>
                  <IoMenuOutline onClick={() => setToggled(!toggled)} size={25} />
                </div>
              )}
              <AppRoutes />
            </main>
          }
          {window.innerWidth < 768 &&
            <main className='mainwidthset'>
              {broken && (
                <div className='d-flex align-items-center setmenuicon'>
                  <IoMenuOutline onClick={() => setToggled(!toggled)} size={25} />
                  <div className='calenderwordother'>{active === "Calendar" ? `MM ${active}` : active}</div>
                </div>
              )}
              <AppRoutes />
            </main>
          }
        </>)}
    </div>
  );
}
export default App;
