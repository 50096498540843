import React, { useEffect, useState, useRef } from 'react';
import "./Calender.css";
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { getCalenderData, getCalenderDaysData, getStaticData } from '../../CalenderFunction/calenderData';
import { Link, useNavigate } from 'react-router-dom';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Holidaydata from '../../CalenderFunction/holiday';
import { WiMoonAltNew } from "react-icons/wi";
import { PiGreaterThanBold } from "react-icons/pi";
const Calendar = () => {
   // State declarations
   const [year, setYear] = useState(new Date().getFullYear());
   const [month, setMonth] = useState(new Date().getMonth());
   const [dates, setDates] = useState([]);
   const [currentDate, setCurrentDate] = useState({});
   const [selectDate, setSelectDate] = useState({});
   const [monthList, setMonthList] = useState([]);
   const [calenderType, setCalenderType] = useState([]);
   const [calenderLanguage, setCalenderLanguage] = useState([]);
   const [calenderTypeId, setCalenderTypeId] = useState(1);
   const [calenderLanguageId, setCalenderLanguageId] = useState(1);
   const [weekdayHeader, setweekdayHeader] = useState([]);
   const [isEditingYear, setIsEditingYear] = useState(false);
   const [calenderData, setCalenderData] = useState([]);
   const [jsValues, setJsValues] = useState([]);
   const [jsId, setJsId] = useState("");
   const [headerLine, setHeaderLine] = useState("");
   const [data, setData] = useState({});
   const [daFootUl, setDaFootUl] = useState([]);
   const [holidays, setHolidays] = useState([]);
   const monthRefs = useRef([]);
   const [fullMoon, setFullMoon] = useState([])
   const [newMoon, setNewMoon] = useState([])
   const [waxingMoon, setWaxingMoon] = useState([])
   const [WaningMoon, setWaningMoon] = useState([])

   // Fetch static data on initial render
   useEffect(() => {
      const fetchData = async () => {
         const data = await getStaticData();
         setMonthList(data.month);
         setCalenderType(data.type);
         setCalenderLanguage(data.language);
      };

      fetchData();
      const today = new Date();
      setCurrentDate({
         date: today.getDate(),
         month: today.getMonth(),
         year: today.getFullYear(),
      });
   }, []);

   // Fetch calendar data 
   useEffect(() => {
      const fetchDate = async () => {
         const data = await getCalenderData(month + 1, year, calenderTypeId - 1, calenderLanguageId - 1);
         setweekdayHeader(data.WeekdayHeader);
         const chunks = chunkArray(data.calenderArr, 7);
         const englishDays = extractEnglishDays(chunks);
         setCalenderData(englishDays);
         setHeaderLine(data.headerLine)

         const fullMoonDays = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "Full Moon")
            .map((ele) => ele.EnglishDay);
         setFullMoon(fullMoonDays);


         const newMoonDays = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "New Moon")
            .map((ele) => ele.EnglishDay);
         setNewMoon(newMoonDays);

         const WaxingDay = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "Waxing 8")
            .map((ele) => ele.EnglishDay);
         setWaxingMoon(WaxingDay);

         const WaningDay = data.calenderArr
            .filter((ele) => ele.englishMoonPhaseAndDay === "Waning 8")
            .map((ele) => ele.EnglishDay);
         setWaningMoon(WaningDay);

      }
      fetchDate();
   }, [month, year, calenderTypeId, calenderLanguageId]);
   // Fetch data for the selected date 
   useEffect(() => {
      const fetchSelectDate = async () => {
         const data = await getCalenderDaysData(jsId, calenderTypeId, calenderLanguageId - 1);
         setData(data)
      }
      fetchSelectDate();
   }, [jsId, calenderTypeId, calenderLanguageId - 1]);

   // Fetch data for the current date on initial render 
   useEffect(() => {
      fetchCurrentDateData();
   }, [currentDate, calenderTypeId, calenderLanguageId, month, year]);

   const fetchCurrentDateData = async () => {        
      if (currentDate.date && currentDate.month != null && currentDate.year) {
         const js = (await getCalenderData(month + 1, year, calenderTypeId - 1, calenderLanguageId - 1)).calenderArr.find(
            day => day.englishDaysClass === "PriDayToday"
         )?.js;

         if (js) {
            const data = await getCalenderDaysData(js, calenderTypeId, calenderLanguageId - 1);
            setJsId(js);
            setSelectDate({})
            // setSelectDate({
            //    EnglishDay: currentDate.date,
            //    month: currentDate.month,
            //    year: currentDate.year
            // });
            setData(data);
         }
      }
   }

   useEffect(() => {
      fetchSelectDateData();
   }, [selectDate, calenderTypeId, calenderLanguageId]);

   const fetchSelectDateData = async () => {        
      if (selectDate.EnglishDay && selectDate.month != null && selectDate.year) {
         const jsId = (await getCalenderData(selectDate.month + 1, selectDate.year, calenderTypeId - 1, calenderLanguageId - 1)).calenderArr.find(
            day => day.EnglishDay === selectDate.EnglishDay
         )?.js;
         if (jsId) {
            const data = await getCalenderDaysData(jsId, calenderTypeId, calenderLanguageId - 1);
            setData(data);
         }
      }
   }

   // Handle year input change
   const handleYearChange = (e) => {
      const value = e.target.value;
      if (value === '' || /^[0-9]+$/.test(value)) {
         setYear(value);
         setIsEditingYear(true);
      }
   };

   // Handle year input blur event to set the year within the valid range
   const handleYearBlur = () => {
      let newYear = parseInt(year, 10);
      if (isNaN(newYear)) {
         newYear = new Date().getFullYear();
      }
      if (newYear < 640) {
         newYear = 640;
      } else if (newYear > 2142) {
         newYear = 2142;
      }
      setYear(newYear);
      setIsEditingYear(false);
   };

   // Handle Enter key press for year input
   const handleYearKeyPress = (e) => {
      if (e.key === 'Enter') {
         handleYearBlur();
      }
   };

   const setJsIdfun = (id) => {
      setJsId(id);
   };

   // Handle previous year button click
   const handlePreviousYear = () => {
      setYear((prevYear) => {
        const newYear = Math.max(prevYear - 1, 640);
    
        if (month === currentDate.month && newYear === currentDate.year) {
          fetchCurrentDateData();
          setSelectDate({})
        } else {
          fetchSelectDateData();
        }
    
        return newYear;
      });
    };
    

   // Handle next year button click
   const handleNextYear = () => {
      setYear((prevYear) => {
        const newYear = Math.min(prevYear + 1, 2142);
    
        if (month === currentDate.month && newYear === currentDate.year) {
          fetchCurrentDateData();
          setSelectDate({})
        } else {
          fetchSelectDateData();
        }
    
        return newYear;
      });
    };
    

   // Handle previous month button click
   const handlePreviousMonth = () => {
      setMonth((prevMonth) => {
         const newMonth = prevMonth === 0 ? 11 : prevMonth - 1;
         if (prevMonth === 0) setYear((prevYear) => Math.max(prevYear - 1, 640));
         scrollToMonth(newMonth);
         if (newMonth === currentDate.month && year === currentDate.year) {
            fetchCurrentDateData();
            setSelectDate({})
          } else {
            fetchSelectDateData();
          }
         return newMonth;
      });
   };

   // Handle next month button click
   const handleNextMonth = () => {
      setMonth((prevMonth) => {
         const newMonth = prevMonth === 11 ? 0 : prevMonth + 1;
         if (prevMonth === 11) setYear((prevYear) => Math.min(prevYear + 1, 2142));
         scrollToMonth(newMonth);
         if (newMonth === currentDate.month && year === currentDate.year) {
            fetchCurrentDateData();
            setSelectDate({})
          } else {
            fetchSelectDateData();
          }
         return newMonth;
      });

   };

   // Scroll to the specified month element
   const scrollToMonth = (monthIndex) => {
      if (monthRefs.current[monthIndex]) {
         monthRefs.current[monthIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
   };

   function chunkArray(array, chunkSize) {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
         chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
   }

   function extractEnglishDays(chunks) {
      return chunks.map(chunk =>
         chunk.map(item => ({
            EnglishDay: item.EnglishDay || "",
            js: item.js || "",
            englishDaysClass: item.englishDaysClass || ""
         }))
      );
   }

   // Generate calendar dates for the specified year and month
   const generateCalendarDates = (year, month) => {
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const dates = [];
      let week = Array(firstDayOfMonth).fill('');

      for (let day = 1; day <= daysInMonth; day++) {
         if (week.length === 7) {
            dates.push(week);
            week = [];
         }
         week.push(day);
      }

      while (week.length < 7) {
         week.push('');
      }
      dates.push(week);

      return dates;
   };

   // Generate calendar dates whenever year or month changes
   useEffect(() => {
      setDates(generateCalendarDates(year, month));
   }, [year, month]);

   // Scroll to the current month element on initial render and whenever monthList changes
   useEffect(() => {
      if (monthRefs.current.length > 0) {
         scrollToMonth(new Date().getMonth());
      }
   }, [monthList]);

   useEffect(() => {
      const fetchHolidayData = async () => {
         const data = await Holidaydata();
         setHolidays(data.holidays);
      };

      fetchHolidayData();
   }, []);

   const filteredHolidays = holidays.filter(
      holiday => new Date(holiday.date).getMonth() === month && new Date(holiday.date).getFullYear() === year
   );

   const navigate = useNavigate();

   const moveToMyanmar = () => {
      navigate("/myanmarcalender")
   }

   const getFetchMonth = (index) => {
      setMonth(index);
    
      if (index === currentDate.month && year === currentDate.year) {
        fetchCurrentDateData();
        setSelectDate({})
      } else {
        fetchSelectDateData();
      }
    };
    


   return (
      <Container fluid>
         <Row className='setscreenheight'>
            <Col xl={3} lg={4} md={3} className='p-0'>
               <div className='calendersecondsec'>
               {window.innerWidth >= 768 &&
                  <div className='calenderword'>MM Calendar</div>}
                  {window.innerWidth >= 768 &&
                     <div className="text-center setyearmonth">
                        <div className='pb-md-3 setyearresponsive'>
                           <div className="d-flex align-items-center justify-content-center manageinputwidth ">
                              <FaCaretLeft onClick={handlePreviousYear} className='iconcolorsetanother' size={35} />
                              <Form.Control
                                 type="text"
                                 value={year}
                                 onChange={handleYearChange}
                                 onBlur={handleYearBlur}
                                 onKeyDown={handleYearKeyPress}
                              />
                              <FaCaretRight onClick={handleNextYear} className='iconcolorsetanother' size={35} />
                           </div>
                        </div>

                        <div className='setarrowres'>
                           {window.innerWidth >= 768 &&
                              <TiArrowSortedUp size={35} className='iconcolorset my-4' onClick={handlePreviousMonth} />
                           }
                           {window.innerWidth <= 767 &&
                              <FaCaretLeft size={35} className='iconcolorset my-4' onClick={handlePreviousMonth} />
                           }
                           <div className="calandermonthscroll setmonthresponsive">
                              {monthList.map((monthName, index) => (
                                 <div
                                    key={index}
                                    ref={(el) => monthRefs.current[index] = el}
                                    className={`monthcolorset pb-md-4 ${month === index ? 'active-month' : ''}`}
                                    onClick={() => { setMonth(index); scrollToMonth(index) ; getFetchMonth(index)}}
                                 >
                                    {monthName.name}
                                 </div>
                              ))}
                           </div>
                           {window.innerWidth >= 768 &&
                              <TiArrowSortedDown size={35} className='iconcolorset mb-4' onClick={handleNextMonth} />
                           }
                           {window.innerWidth <= 767 &&
                              <FaCaretRight size={35} className='iconcolorset my-4' onClick={handleNextMonth} />
                           }
                        </div>


                     </div>
                  }
                  {window.innerWidth < 768 &&
                     <Row className='m-0 py-4'>
                        <Col lg={6} xs={6}>
                           <div className="d-flex align-items-center justify-content-center manageinputwidth">
                              <FaCaretLeft className='iconcolorsetanother' size={40} onClick={handlePreviousYear} />
                              <Form.Control
                                 type="text"
                                 value={year}
                                 onChange={handleYearChange}
                                 onBlur={handleYearBlur}
                                 onKeyDown={handleYearKeyPress}
                              />
                              <FaCaretRight className='iconcolorsetanother' size={40} onClick={handleNextYear} />
                           </div>
                        </Col>
                        <Col lg={6} xs={6}>
                           <div className='d-flex align-items-center'>
                              <FaCaretLeft size={40} className='iconcolorsetmobil' onClick={handlePreviousMonth} />
                              <div className="w-100 conditionalset">
                              {monthList.map((monthName, index) => (
                                 <div
                                    key={index}
                                    ref={(el) => monthRefs.current[index] = el}
                                    className={`monthcolorset pb-md-4 ${month === index ? 'active-month' : ''}`}
                                    onClick={() => { setMonth(index); scrollToMonth(index); getFetchMonth(index) }}
                                 >
                                    {monthName.name}
                                 </div>
                              ))}
                              </div>
                              <FaCaretRight size={40} className='iconcolorsetmobil' onClick={handleNextMonth} />
                           </div>
                        </Col>
                     </Row>
                  }
                  <div className='wasosetflex'>
                     <div className='setwasocalender'>
                        <div className='wasocalender' onClick={() => moveToMyanmar()}>
                           Myanmar<PiGreaterThanBold size={14} />English Calendar
                        </div>
                     </div>
                  </div>
               </div>
            </Col>
            <Col xl={9} lg={8} md={9} className='p-0'>
               <div className='calenderthirdsection'>
                  <div className="setmenubar">
                     {window.innerWidth <= 575 && <div className="setselectmenu">
                        <select className="form-select setselectcolor" onChange={(e) => { setCalenderTypeId(e.target.value);}}>
                           {calenderType.map(option => (
                              <option key={option.id} value={option.id}>
                                 {option.name}
                              </option>
                           ))}
                        </select>
                        <select className="form-select setselectcolor" onChange={(e) => { setCalenderLanguageId(e.target.value);  }}>
                           {calenderLanguage.map(option => (
                              <option key={option.id} value={option.id}>
                                 {option.name}
                              </option>
                           ))}
                        </select>
                     </div>}
                     <div className='datessetfont'>{monthList[month]?.name} {year}</div>
                     {window.innerWidth >= 576 &&

                        <div className="setselectmenu">
                           <select className="form-select setselectcolor" onChange={(e) => { setCalenderTypeId(e.target.value); }}>
                              {calenderType.map(option => (
                                 <option key={option.id} value={option.id}>
                                    {option.name}
                                 </option>
                              ))}
                           </select>
                           <select className="form-select setselectcolor" onChange={(e) => { setCalenderLanguageId(e.target.value);  }}>
                              {calenderLanguage.map(option => (
                                 <option key={option.id} value={option.id}>
                                    {option.name}
                                 </option>
                              ))}
                           </select>
                        </div>
                     }
                  </div>
                  <div className='headerline'>{headerLine}</div>
                  <Table className="calendar-table mb-1">
                     <thead>
                        <tr>
                           {weekdayHeader.map((day, index) => (
                              <th key={index} className="calendar-header">{day}</th>
                           ))}
                        </tr>
                     </thead>
                     <tbody>
                        {calenderData.map((week, index) => (
                           <tr key={index}>
                              {week.map((date, idx) => {
                                 var toDayDateClass = date.englishDaysClass;
                                 let currentDatestyle = "d-flex justify-content-center";
                                 let currentDatestylediv = "";
                                 let lightcoloursetselect = "lightcolourset-unselect";
                                 if (
                                    toDayDateClass == "PriDayToday"
                                    // currentDate.date === date.EnglishDay &&
                                    // currentDate.year === year &&
                                    // currentDate.month === month
                                 ) {
                                    currentDatestylediv = "currentDatecoloure";
                                    lightcoloursetselect = "lightcolourset-currentDate";
                                 }
                                 if (
                                    selectDate.EnglishDay === date.EnglishDay &&
                                    selectDate.year === year &&
                                    selectDate.month === month &&
                                    selectDate !== ""
                                 ) {
                                    currentDatestylediv = "selectedDatecoloure";
                                    lightcoloursetselect = "lightcolourset-select";
                                 }
                                 return (
                                    <td
                                       key={idx}                                     
                                    >
                                       <div className={currentDatestyle}>
                                          <div className={lightcoloursetselect}
                                           onClick={() => {
                                             if (date.EnglishDay) {
                                                setSelectDate({EnglishDay: date.EnglishDay, month, year  });
                                                setJsIdfun(date.js);
                                             }
                                          }}
                                          >
                                             <div className={currentDatestylediv}>{date.EnglishDay}</div>
                                          </div>
                                       </div>
                                    </td>
                                 );
                              })}
                           </tr>
                        ))}
                     </tbody>
                  </Table>

                  <div className='managesdetailscroll'>
                     <div className='managespacedetails'>
                        {
                           data?.MyanmarDate &&
                           <>
                              <div className='sidebardetailsfirstsizeset'>{data.SasanaYear}</div>
                              <div className='sidebardetailsfirstsizeset'>{data.MyanmarYear}</div>
                              <div className={data.moonPhaseClass}>{data.moonPhase}</div>
                              <div className='sidebardetailsfirstsizeset'>{data.weekday}</div>
                              {
                                 data?.sabbathAndMoon && <>
                                    <div className='calenderdatesset d-flex align-items-center'>{data?.sabbathAndMoon}  {data?.canvas && <div className={data?.canvas} />}</div>
                                 </>
                              }
                              {
                                 data?.holidays.length !== 0 && (
                                    <div
                                       className='MCPubHol'
                                       dangerouslySetInnerHTML={{ __html: data.holidays.join('\n').replace(/\n/g, '<br />') }}
                                    />
                                 )
                              }
                              {
                                 data?.holidays2.length !== 0 && (
                                    <div
                                       className='weekdayclass'
                                       dangerouslySetInnerHTML={{ __html: data.holidays2.join('\n').replace(/\n/g, '<br />') }}
                                    />
                                 )
                              }
                              {data.yatyaza && <div className='calenderdatesset'>{data.yatyaza}</div>}
                              {data.pyathada && <div className='calenderdatesset'>{data.pyathada}</div>}
                              {
                                 data?.astro.length !== 0 && (
                                    <div
                                       className='sidebardetails'
                                       dangerouslySetInnerHTML={{ __html: data.astro.join('\n').replace(/\n/g, '<br />') }}
                                    />
                                 )
                              }
                              <div className='sidebardetails'>{data.yearName}</div>
                              <div className='sidebardetails'>{data.mahabote}</div>
                              <div className='sidebardetails'>{data.nakhat}</div>
                              <div className='sidebardetails'>{data.nagaHead}</div>
                              {
                                 data?.ThingyanLink && (
                                    <Link
                                       to={data.ThingyanLink}
                                       className='anchorFootMC'
                                       target="_blank"

                                    >
                                       Thingyan Calculator &gt;&gt;
                                    </Link>
                                 )
                              }
                              <div className='sidebardetailsfontsmall'>{data.DaFoot}</div>
                              {
                                 data?.MCAstro && <div className='sidebardetails'>{data?.MCAstro}</div>
                              }
                              {/* <ul className="">
                              {data.daFootUl.map((item, index) => (
                                 <li key={index} className="">
                                    <div></div>
                                    <Link to={item.r_url} target="_blank" >{item.r_url}</Link>
                                    <div>{item.r_name}</div>
                                    <Link to={item.dyn_url} target="_blank" >{item.dyn_url}</Link>
                                    <div>{item.dyn_Description}</div>
                                 </li>
                              ))}
                           </ul> */}
                              {
                                 data?.ImportantNote && <div className='sidebardetailsfontsmall'>{data?.ImportantNote}</div>
                              }
                           </>
                        }
                     </div>
                     <div className='phaseofmoon py-1 d-flex justify-content-center align-items-center flex-wrap'>
                        <div className='moonsetcenter text-white'>Phases of the Moon : </div>
                        <div className='d-flex align-items-center moongap pl-1'>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {newMoon.join(",")}:</span> <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                              <rect width="13" height="13" fill="black" rx="50" ry="50" />
                           </svg>
                           </div>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {waxingMoon.join(",")}:</span> <svg width="13" height="13" viewBox="0 0 100 100">
                              <path d="M 50 0 A 50 50 0 0 0 50 100 L 50 50 Z" fill="black" />
                              <path d="M 50 0 A 50 50 0 0 1 50 100 L 50 50 Z" fill="white" />
                           </svg>
                           </div>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {fullMoon.join(",")}:</span> <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                              <rect width="13" height="13" fill="white" rx="50" ry="50" />
                           </svg>
                           </div>
                           <div className='d-flex align-items-center moonresponsivespace'><span className='setmonthmooncolor'> {WaningMoon.join(",")}:</span> <svg width="13" height="13" viewBox="0 0 100 100">
                              <path d="M 50 0 A 50 50 0 0 0 50 100 L 50 50 Z" fill="white" />
                              <path d="M 50 0 A 50 50 0 0 1 50 100 L 50 50 Z" fill="black" />
                           </svg>

                           </div>
                        </div>
                     </div>
                     <div className='setholidayscroll'>
                        {filteredHolidays.length > 0 && (
                           <>
                              <div className='holidaytitle'>Holiday and Observances</div>
                              {filteredHolidays.map((holiday, index) => (
                                 <div key={index} className='holidaydataspace'>
                                    <span className='datecolorset'> {new Date(holiday.date).getDate()}</span> : <span className='holidayname'>{holiday.name}
                                       {holiday.comments && holiday.comments !== '--' ? ` (${holiday.comments})` : ''}</span>
                                 </div>
                              ))}
                           </>
                        )}
                     </div>
                  </div>
               </div>
            </Col>
         </Row>
      </Container>
   );
};

export default Calendar;
